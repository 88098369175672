import { isMobile } from "react-device-detect";

export const Experience = (props) => {
  return isMobile ? (
    <div id="row text-center">
      <div className="col-md-3 col-sm-6 team text-center">
        <div className="section-title">
          <h2>Experience</h2>
        </div>
        <img
          src="img/my_exp_2023_mobile.jpg"
          className="team-img img-responsive"
          alt=""
        />
      </div>
    </div>
  ) : (
    <div id="about text-center">
      <div className="container text-center">
        <div className="section-title">
          <h2>Experience</h2>
        </div>
        <img src="img/Slide1.jpg" className="img-responsive" alt="" />
      </div>
    </div>
  );
};
