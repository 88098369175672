export const Personality = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>My Personality</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3>GENOTYPE</h3>
            <p>FEELING INTROVERT</p>
            <img
              src="img/my_stifin.jpg"
              className="team-img img-responsive"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div>
              <h3>PHENOTYPE</h3>
              <p>INTJ</p>
              <img
                src="img/my_mbti.png"
                className="team-img img-responsive"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
